import React from 'react';
import { Link } from 'gatsby';
import Swiper from 'react-id-swiper';
import Image from '../util/Image';

import 'swiper/css/swiper.css';
import '../sass/components/_slider.scss';

class SimpleSwiper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: '',
      didMount: '',
      loading: true,
      pageTitle: props.pageTitle,
      slides: props.data,
      // swiperObj: {},
      params: {
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        /*        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }, */
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        // spaceBetween: 50,
        centeredSlides: true,
        slidesPerView: 1,
        loop: true,
        // loopAdditionalSlides: 3,
        effect: 'fade',
        speed: 1200,
        /*        breakpoints: {
          1000: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 3,
          },
          640: {
            slidesPerView: 1,
          },
          300: {
            slidesPerView: 1,
          },

        }, */
        rebuildOnUpdate: true,
        getSwiper: (swiper) => {
          swiper.on('slideChange', () => {
            // console.log(`slide changed : ${swiper.activeIndex}`);
          });
        },
      },
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        didMount: true,
      });
    }, 0);

    setTimeout(() => {
      this.setState({
        loading: '',
      });
    }, 400);

    setTimeout(() => {
      this.setState({
        visible: true,
      });
    }, 600);
  }

  /*
  updateSwiper = ($obj) => {
    this.setState({
      swiperObj: $obj,
    });
  }
*/

  /*
  goNext = () => {
    if (this.state.swiperObj !== null) {
      this.state.swiperObj.slideNext();
    }
  }

  goPrev = () => {
    if (this.state.swiperObj !== null) {
      this.state.swiperObj.slidePrev();
    }
  }

  goSlideTo = (index) => {
    if (this.state.swiperObj !== null) {
      this.state.swiperObj.slideTo(index, 800);
    }
  }
*/

  render() {
    const {
      slides, visible, didMount, loading, params, pageTitle,
    } = this.state;
    const num = slides.length;
    return (
      <>
        {num > 1 ? (
          <div className={`slider-wrap slider-fade kv-image ${didMount && 'is-didMount'} ${visible && 'is-visible'}`}>
            { !visible && (
            <div className={`loader-wrapper ${loading && 'loading'}`}>
              <div className="loader is-loading" />
            </div>
            )}
            <Swiper {...params}>
              {
            slides.map((obj) => (
              <div className="slide swiper-no-swiping" key={obj.filename}>
                <div>
                  {obj.link && obj.external && (
                    <a href={obj.link} target="_blank" rel="noopener noreferrer">
                      {obj.noAnimation ? (
                        <img src={obj.filename} alt="" className="no-animate" />
                      ) : (
                        <Image filename={obj.filename} alt="" className="" />
                      )}
                    </a>
                  ) }

                  {obj.link && !obj.external && (
                    <Link to={obj.link}>
                      {obj.noAnimation ? (
                        <img src={obj.filename} alt="" className="no-animate" />
                      ) : (
                        <Image filename={obj.filename} alt="" className="" />
                      )}
                    </Link>
                  ) }

                  { !obj.link && !obj.external && (
                    <>
                      {obj.noAnimation ? (
                        <img src={obj.filename} alt="" className="no-animate" height="672" />
                      ) : (
                        <>
                          <div className={ obj.copy && "mb10"}><Image filename={obj.filename} alt="" className="" height="672" /></div>
                          { obj.copy && <p className="tar c-0 text is-0">{obj.copy}</p>}
                          </>
                      )}
                    </>
                  ) }

                </div>
              </div>
            ))
          }
            </Swiper>
          </div>
        ) : (
          <div className="kv-image">
            {slides[0].noAnimation ? (
              <img src={slides[0].filename} alt={pageTitle} className="no-animate" />
            ) : (
              <Image filename={slides[0].filename} alt={pageTitle} height="672" />
            )}
          </div>
        )}
      </>
    );
  }
}

export default SimpleSwiper;
