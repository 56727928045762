/**
 * Type: コンポーネント
 * What: コンテンツリスト
 */
import { Link } from 'gatsby';
import React from 'react';
import Image from '../util/Image';

export const List = (props) => (
  <div className="flex-moodal-col">
    <div className="col-image">
      <figure className="image">
        <Image filename={props.img} width="284" height="200" />
      </figure>
    </div>
    <div className="col-content bg-12">
      <h3 className="ttl c-4">
        {props.ttl}
      </h3>
      <p>{props.desc}</p>
    </div>
  </div>
);
const ContentsListSummer = (props) => (
  <>
    {props.isModal ? (
      <div className="col-inner isModal">
        <List {...props} />
      </div>
    ) : (
      <Link to={props.link} className="col-inner">
        <List {...props} />
      </Link>
    )}
  </>
);

export default ContentsListSummer;
