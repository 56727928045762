/**
 * Type: ページ
 * What: イーゾの夏イベント「2021SUMMER＆1stANNIVERSARY」 ｜BOSS E・ZO FUKUOKA（ボス イーゾ フクオカ）公式サイト
 */
import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { scroller } from 'react-scroll';
import Layout from '../../../components/Layout';
import SEO from '../../../components/Seo';
import BreadcrumbComponent from '../../../components/Breadcrumb';
import useMediaQuery from '../../../util/useMediaQuery';
import Image from '../../../util/Image';
import SimpleSwiper from '../../../components/Slider.lp';
import {SUMMER_GOODS, OTHER_EVENTS} from '../../../config/Summer';
import ContentsListSummer from '../../../components/ContentsListSummer';
import {SummerModal1, SummerModal2, SummerModal3, SummerModal4, SummerModal5, SummerModal6} from '../../../components/ModalListSummer';
import '../../../sass/components/_webfont-puikko.scss';// .puikko
import '../../../sass/components/_webfont-mplus1p.scss';
import mainImg from '../../../images/event/summer-1st_anniv/summer-mv.jpg';

const OGP = '/ogp/event-summer-ogp.jpg';// ogp画像

const mq = useMediaQuery;

const pageTitle = 'イーゾの夏イベント「2021SUMMER＆1stANNIVERSARY」 ｜BOSS E・ZO FUKUOKA（ボス イーゾ フクオカ）公式サイト';
const pageDescription = '7月21日（水）に開業1周年を迎える「BOSS E・ZO FUKUOKA」にて、7月17日（土）～8月29日（日）の期間、“ひまわり”をモチーフにしたアトラクションやグルメなど夏を満喫できるイベント「2021SUMMER&1stANNIVERSARY」を開催します！';
const pageSlug = 'event-summer';

const sliderDataobj = [
  {
    filename: mainImg,
    noAnimation: true,
  },
];

const SpotImage = (porps) => {

  const {slide} = porps;
  const pcImage = slide.map((obj)=>(
      <li key={obj.filename}>
        <div className={ obj.copy && "mb10"}><Image filename={obj.filename} width="1210" height="672" /></div>
      </li>
    )
  );

  return mq("pc") ? <ul>{pcImage}</ul>  :  <SimpleSwiper data={slide} /> ;
};
const scrollFunc = (porps,Offset) => {
  scroller.scrollTo(porps, {
    duration: 800,
    delay: 0,
    offset: Offset,
    smooth: 'easeOutQuint',
  });
};

// Data Props Template
const Template = ({ data }) => {
  const [Offset, setOffset] = useState(false);
  const scrollOffset = mq("pc") ? -100 : -60;

  const [isModalGoods, setIsModalGoods] = useState(-1);
  const [isModalOther, setIsModalOther] = useState(-1);

  // モーダル展開
  const onClickModal = (e, type) => {
    const num = Number(e.currentTarget.dataset.modal);
    if (type == 'goods') {
      setIsModalGoods(num);
    } else {
      setIsModalOther(num);
    }
  };

  // モーダル閉じる
  const onCloseModal = (type) => {
    if (type == 'goods') {
      setIsModalGoods(-1);
    } else {
      setIsModalOther(-1);
    }
  };

  // モーダルコンテンツ
  const ModalContent = () => {
    if (SUMMER_GOODS[isModalGoods] && SUMMER_GOODS[isModalGoods].isModal) {
      const modalName = SUMMER_GOODS[isModalGoods] && SUMMER_GOODS[isModalGoods].modalName;
      const modalGroup = SUMMER_GOODS[isModalGoods] && SUMMER_GOODS[isModalGoods].group;

      return (
        <div className={`modal e-modal ${isModalGoods > -1 ? 'is-active' : ''}`}>
          {/* eslint-disable-next-line max-len */}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div className="modal-background" onClick={() => onCloseModal(modalGroup)} />
          <button
            type="button"
            className="delete"
            aria-label="close"
            data-sal="zoom-in"
            data-sal-delay="300"
            data-sal-easing="ease-out-expo"
            onClick={() => onCloseModal(modalGroup)}
          />
          <div className="modal-content">
            {modalName === "SummerModal1" && <SummerModal1 />}
            {modalName === "SummerModal2" && <SummerModal2 />}
            {modalName === "SummerModal3" && <SummerModal3 />}
            {modalName === "SummerModal4" && <SummerModal4 />}
            {modalName === "SummerModal5" && <SummerModal5 />}
            {modalName === "SummerModal6" && <SummerModal6 />}
          </div>
        </div>
      );
    }
    if (OTHER_EVENTS[isModalOther] && OTHER_EVENTS[isModalOther].isModal) {
      const modalName = OTHER_EVENTS[isModalOther] && OTHER_EVENTS[isModalOther].modalName;
      const modalGroup = OTHER_EVENTS[isModalOther] && OTHER_EVENTS[isModalOther].group;
      return (
        <div className={`modal e-modal ${isModalOther > -1 ? 'is-active' : ''}`}>
          {/* eslint-disable-next-line max-len */}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div className="modal-background" onClick={() => onCloseModal(modalGroup)} />
          <button
            type="button"
            className="delete"
            aria-label="close"
            data-sal="zoom-in"
            data-sal-delay="300"
            data-sal-easing="ease-out-expo"
            onClick={() => onCloseModal(modalGroup)}
          />
          <div className="modal-content">
            {modalName === "SummerModal1" && <SummerModal1 />}
            {modalName === "SummerModal2" && <SummerModal2 />}
            {modalName === "SummerModal3" && <SummerModal3 />}
            {modalName === "SummerModal4" && <SummerModal4 />}
            {modalName === "SummerModal5" && <SummerModal5 />}
            {modalName === "SummerModal6" && <SummerModal6 />}
          </div>
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    setOffset(scrollOffset);
  },[scrollOffset]);

  return (
    <Layout pageSlug={pageSlug} bg="bg-4">
      <SEO
        title={pageTitle}
        description={pageDescription}
        ogImage={OGP}
      />
      <BreadcrumbComponent
        hierarchy={2}
        bread2url="obm/"
        bread2title={pageTitle}
      />

      <section className="pageMain bg-12 bs-4">
        <div className="kv-wrap">
          <div className="kv-inner">
            <SimpleSwiper data={sliderDataobj} />
          </div>
        </div>
        <div className="parallax-wrap">
          <div className="description">
            <div data-sal="slide-up"
              data-sal-easing="ease-out-expo"
              className="inner">
              <p>
                気持ちのよい絶景や非日常空間など楽しい刺激がたくさん！{ mq("pc") && <br /> }
                動いたあとはおいしいグルメでおなかも満たされる♪<br />
                イーゾで家族やともだちと楽しもう☆
              </p>
            </div>
          </div>
{/*          {mq("pc") && <Parallax speed={-1} percentage={0.5} className="img">
            <Image filename="gw-img-parallax.jpg"/>
          </Parallax>
          } */}
        </div>

        <div className="about_measures">
          <Link
            to="/news/info/20230311252/"
            className=""
            data-sal="slide-up"
            data-sal-easing="ease-out-expo"
          >
            <Image filename="gw-mask.png" />
                  <span>
                    新型コロナウイルス感染防止の対策について
                  </span>
            <Image filename="gw-thermometer.png" />
          </Link>
        </div>

        <div className="container is-fluid">

          <div className="sc-01">
            <div className="inner tac">
              <p
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="tac text is-3 is-3-sp ls-5 lh-5 mplus1p fwb mb10">
                7.17<span className="text is-1 is-2-sp">sat</span> - 8.29<span className="text is-1 is-2-sp">sun</span><br />
                BOSS E・ZO FUKUOKA 1周年夏の祭典！
              </p>
            </div>
          </div>
        </div>

        <div
          data-sal="slide-up"
          data-sal-easing="ease-out-expo"
          className="linkInPage">
          <ul>
            <li><button type="button" className="puikko" onClick={()=>scrollFunc('event',Offset)}>{mq("pc") && <>今だけ！見逃せない<br /></>}期間限定<br className="sp"/>イベント</button></li>
            <li><button type="button" className="puikko" onClick={()=>scrollFunc('gourmet',Offset)}>{mq("pc") && <>ひまわりモチーフや黄色の<br /></>}限定<br className="sp"/>グルメ！</button></li>
            <li><button type="button" className="puikko" onClick={()=>scrollFunc('zekkei',Offset)}>{mq("pc") && <>絶景で絶叫！<br /></>}アトラク<br className="sp"/>ション集</button></li>
          </ul>
        </div>

        {/* チームラボ */}
        <div className="container is-fluid container-arrow bg-1" id="event">
          <div className="event" id="event">
            <h2 data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="title is-2 c-0">
              いまだけ！見逃せない<br className="sp"/>
              期間限定イベント
            </h2>
            <div className="inner">
              <div className="spot">
                <div
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  className="spot-image">
                  <Image filename="teamlab-image.jpg" width="1440" height="650" />
                </div>
                <div
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  className="spot-slide spot-slide-1">

                  <SpotImage slide={
                    [{
                      filename: 'teamlab-slide-1.jpg',
                    },
                    {
                      filename: 'teamlab-slide-2.jpg',
                    },]
                  } />

                </div>
                <div className="spot-col">
                  <div data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="spot-logo">
                    <Link to="/teamlabforest/"><Image filename="teamlab-logo.png" width={ mq("pc") ? 300 : 200 } alt="チームラボフォレスト 福岡 – SBI証券" /></Link>
                  </div>
                  <p
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="tal text is-1 is-3-sp ls-5 lh-9">
                    チームラボの常設ミュージアム「チームラボフォレスト 福岡 – SBI証券」では、夏の季節限定でひまわりをはじめとした、夏の花々でできた動物たちが登場します。立体的な世界に咲くひまわりは圧巻の様相です。暑い夏を涼しく楽しく過ごしませんか？
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

       {/* カブト・クワガタふれあいの森 */}
        <div className="container is-fluid">
          <div className="event">
            <div className="inner">
              <div className="spot">
                <div
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  className="spot-image">
                  <Image filename="kuwagata-image.jpg" width="1440" height="650" />
                </div>
                <div className="spot-col">
                  <div
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="spot-logo">
                    <a href="/fureainomori/">
                      <p className="subtitle is-4 fwb tac sal-animate logo-text">カブト・クワガタふれあいの森 in 福岡</p>
                    </a>
                  </div>
                  <p
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="tal text is-1 is-3-sp ls-5 lh-9">
                    7月22日（木・祝）～8月22日（日）の期間限定開催！昆虫たちと直接ふれあえるので、この夏の思い出づくりや自由研究にもぴったりです。展示スペースにはヘラクレスオオカブトやギラファノコギリクワガタも！人気の生体販売やグッズ販売もご用意♪
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* うんこミュージアム */}
        <div className="container is-fluid">
          <div className="event">
            <div className="inner">
              <div className="spot">
                <div
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  className="spot-image">
                  <Image filename="spot1-image.jpg" width="930" />
                </div>
                <div
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  className="spot-slide">

                  <SpotImage slide={
                    [{
                      filename: 'spot1-slide01.jpg',
                    },
                      {
                        filename: 'spot1-slide02.jpg',
                      }]
                  } />

                </div>
                <div className="spot-col">
                  <div
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="spot-logo">
                    <Link to="/unkomuseum/"><Image filename="spot1-logo.png" width={ mq("pc") ? 600 : 300 } height={ mq("pc") ? 146 : 90 } alt="うんこミュージアム" /></Link>
                  </div>
                  <p data-sal="slide-up"
                     data-sal-easing="ease-out-expo"
                     className="tal text is-1 is-3-sp ls-5 lh-9">
                    インスタ映えならぬ「ウンスタ映え」なスポットや何度でも遊びたくなる中毒性の高いクソゲーセンターも！ぜひ童心に返って全力でお楽しみください。
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>

        {/* グッズ・グルメ情報 */}
        <div className="container is-fluid bg-21">
          <div className="goods-info">
            <h3
              data-sal="slide-up"
              data-sal-easing="ease-out-expo"
              className="subtitle is-4 fwb  tac mb-6">グッズ・グルメ情報</h3>
            <div className="inner">
              <div className="sc-columns">
                {/* <div className="sc-columns now-event"> */}
                  {
                    SUMMER_GOODS.map((categories, index) => (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                      <div
                        data-sal="slide-up"
                        data-sal-easing="ease-out-expo"
                        className="col col-4"
                        key={categories.name}
                        data-modal={index}
                        onClick={(e) => onClickModal(e, categories.group)}
                      >
                        <ContentsListSummer {...categories} />
                      </div>
                    ))
                  }
                  {/* </div> */}
              </div>
            </div>
          </div>
        </div>

        {/* 5G LAB FUKUOKA */}
        <div className="container is-fluid">
          <div className="event">
            <div className="inner">
              <div className="spot">
                <div
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  className="spot-image">
                  <Image filename="spot2-image-summer.jpg" width="1440" height="650" />
                </div>
                <div className="spot-col">
                  <div
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="spot-logo">
                    <a href="/?anker-5glab"><Image filename="spot2-logo.png" width={ mq("pc") ? 300 : 100 } height={ mq("pc") ? 411 : 137 } alt="5G LAB FUKUOKA" /></a>
                  </div>
                  <p
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="tal text is-1 is-3-sp ls-5 lh-9">
                    5G環境での最先端の映像コンテンツや、アイドルグループ「NiziU（ニジュー）」とコラボレーションしたオリジナルコンテンツ、ホークス柳田選手とのバッティングフォーム比較「スイングものまねAI診断」が体験できるスペースが登場！
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>

        {/* その他イベント */}
         <div className="container is-fluid bg-21">
          <div className="goods-info">
            <h3
              data-sal="slide-up"
              data-sal-easing="ease-out-expo"
              className="subtitle is-4 fwb  tac mb-6">その他イベント</h3>
            <div className="inner">
              <div className="sc-columns">
                {
                  OTHER_EVENTS.map((otherEvent, index) => (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                    <div
                      data-sal="slide-up"
                      data-sal-easing="ease-out-expo"
                      className="col col-4"
                      key={otherEvent.name}
                      data-modal={index}
                      onClick={(e) => onClickModal(e, otherEvent.group)}
                    >
                      <ContentsListSummer {...otherEvent} />
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>

        {/* パララックス */}
        <div className="parallax-wrap">
{/*          {mq("pc") &&
          <Parallax speed={-1} percentage={0.5} className="img">
            <Image src="gw-img-parallax.jpg"/>
          </Parallax>
          } */}
        </div>

        {/* 絶品グルメ集 */}
        <div className="bg-22">
          <div className="container is-fluid container-arrow bg-none bg-3 gurume" id="gourmet">
            <div className="event" id="event">
              <h2 data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  className="title is-2 c-0">
                おとなも子どもも大満足♪<br />
                絶品グルメ集
              </h2>
            </div>
          </div>
          {/* グッズ・グルメ情報 */}
          <div className="container is-fluid bnone bg-none">
            <div className="goods-info">
              <h3 className="subtitle is-4 fwb tac mb-6">グルメ集</h3>
              <div className="inner">
                <div className="sc-columns mb70">
                  <div data-sal="slide-up"
                       data-sal-easing="ease-out-expo"
                       className="col col-4">
                    <Link to="/the-foodhall/" className="col-inner">
                      <div className="col-image">
                        <figure className="image">
                          <Image filename="summer-gourmet01.jpg" width="284" height="200" />
                        </figure>
                      </div>
                      <div className="col-content bg-12">
                        <h3 className="ttl c-4">
                          サンフラワーバーガー<br />
                          1,700円
                        </h3>
                        <p>
                          3F MLB café FUKUOKA<br />
                          パイナップルをひまわりに見立てたハンバーガー。ピリ辛のチリビーンズとパイナップルの甘酸っぱさがマッチした、夏に食べたくなる味です！
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div data-sal="slide-up"
                       data-sal-easing="ease-out-expo"
                       className="col col-4">
                    <Link to="/the-foodhall/" className="col-inner">
                      <div className="col-image">
                        <figure className="image">
                          <Image filename="summer-gourmet02.jpg" width="284" height="200" />
                        </figure>
                      </div>
                      <div className="col-content bg-12">
                        <h3 className="ttl c-4">
                          サンフラワーピザ<br />
                          1,200円
                        </h3>
                        <p>
                          3F MLB café FUKUOKA<br />
                          ひまわりの花をモチーフにした大胆で夏らしいピザが完成！！プルドポークやコーンなどをたっぷりトッピングしておりお子様でも食べやすい味に仕上がっています。
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div data-sal="slide-up"
                       data-sal-easing="ease-out-expo"
                       className="col col-4">
                    <Link to="/mlbcafe-fukuoka/" className="col-inner">
                      <div className="col-image">
                        <figure className="image">
                          <Image filename="summer-gourmet03.jpg" width="284" height="200" />
                        </figure>
                      </div>
                      <div className="col-content bg-12">
                        <h3 className="ttl c-4">
                          とうもろこしの天ぷら<br />
                          330円
                        </h3>
                        <p>
                          3F 京都 天ぷら 花いかだ<br />
                          いまが旬のとうもろこしを1/2本使用。つぶつぶ食感と、衣のさくさく食感の絶妙な組み合わせ。
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div data-sal="slide-up"
                       data-sal-easing="ease-out-expo"
                       className="col col-4">
                    <Link to="/the-foodhall/" className="col-inner">
                      <div className="col-image">
                        <figure className="image">
                          <Image filename="summer-gourmet04.jpg" width="284" height="200" />
                        </figure>
                      </div>
                      <div className="col-content bg-12">
                        <h3 className="ttl c-4">
                          和牛のパテ<br />
                          550円
                        </h3>
                        <p>
                          3F 焼肉 赤身 にくがとう<br />
                          和牛の旨味を十二分に感じられるパテと、マスタード、付け合わせを一皿に。ワインと共に楽しみたい一品。
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div data-sal="slide-up"
                       data-sal-easing="ease-out-expo"
                       className="col col-4">
                    <Link to="/the-foodhall/" className="col-inner">
                      <div className="col-image">
                        <figure className="image">
                          <Image filename="summer-gourmet05.jpg" width="284" height="200" />
                        </figure>
                      </div>
                      <div className="col-content bg-12">
                        <h3 className="ttl c-4">
                          ターメリックライスのバターチキンカレー<br />
                          1,000円
                        </h3>
                        <p>
                          3F 肉山食堂<br />
                          見た目に鮮やかなターメリックライスと、ゴロッと入ったチキンがボリューム満点のカレー。サラダ、スープ付き。
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div data-sal="slide-up"
                       data-sal-easing="ease-out-expo"
                       className="col col-4">
                    <Link to="/mlbcafe-fukuoka/" className="col-inner">
                      <div className="col-image">
                        <figure className="image">
                          <Image filename="summer-gourmet06.jpg" width="284" height="200" />
                        </figure>
                      </div>
                      <div className="col-content bg-12">
                        <h3 className="ttl c-4">
                          黄金イクラちかっぱ盛り<br />
                          968円
                        </h3>
                        <p>
                          3F 九州 はかた 大吉寿司<br />
                          光り輝くヤマメのたまごをふんだんに盛り付けた見た目も独特の食感もお楽しみいただける一皿。
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div data-sal="slide-up"
                       data-sal-easing="ease-out-expo"
                       className="col col-4">
                    <Link to="/the-foodhall/" className="col-inner">
                      <div className="col-image">
                        <figure className="image">
                          <Image filename="summer-gourmet07.jpg" width="284" height="200" />
                        </figure>
                      </div>
                      <div className="col-content bg-12">
                        <h3 className="ttl c-4">
                          黄色とびことヤリイカ軍艦<br />
                          352円
                        </h3>
                        <p>
                          3F 九州 はかた 大吉寿司<br />
                          お子様にも人気のとびこと、お店自慢のヤリイカの組み合わせ。ヤリイカの甘みとプチプチのとびこをお楽しみ下さい。
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div data-sal="slide-up"
                       data-sal-easing="ease-out-expo"
                       className="col col-4">
                    <Link to="/the-foodhall/" className="col-inner">
                      <div className="col-image">
                        <figure className="image">
                          <Image filename="summer-gourmet08.jpg" width="284" height="200" />
                        </figure>
                      </div>
                      <div className="col-content bg-12">
                        <h3 className="ttl c-4">
                          サーモンツナマヨのたまご巻き<br />
                          286円
                        </h3>
                        <p>
                          3F 九州 はかた 大吉寿司<br />
                          サーモンのツナマヨをたまごで巻き、マヨネーズをかけたお子様の大好きネタが詰まった一品。
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>

                <h3 className="subtitle is-3 fwb  tac mb-6 puikko c-12">
                  <Image src="c-12.svg" width="73" height="57" alt="家族やお友達と福岡市で超たのしいエンタメ三味！ゴールデンウィーク2021" />
                  デザートやドリンクも♪
                </h3>
                <div className="sc-columns">
                  <div data-sal="slide-up"
                       data-sal-easing="ease-out-expo"
                       className="col col-4">
                    <Link to="/the-foodhall/" className="col-inner">
                      <div className="col-image">
                        <figure className="image">
                          <Image filename="summer-dessert01.jpg" width="284" height="200" />
                        </figure>
                      </div>
                      <div className="col-content bg-12">
                        <h3 className="ttl c-4">
                          サマーシェイブドアイスパフェ<br/>
                          1,100円
                        </h3>
                        <p>
                          3F MLB café FUKUOKA<br/>
                          夏の青空に咲くひまわりをイメージした見た目にも夏らしい爽やかなパフェ。<br/>
                          ブルーハワイのシェイブドアイスやレモンソフトクリーム、ひまわり型のクッキーであしらったポップで可愛いパフェをご賞味ください。
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div data-sal="slide-up"
                       data-sal-easing="ease-out-expo"
                       className="col col-4">
                    <Link to="/the-foodhall/" className="col-inner">
                      <div className="col-image">
                        <figure className="image">
                          <Image filename="summer-dessert02.jpg" width="284" height="200" />
                        </figure>
                      </div>
                      <div className="col-content bg-12">
                        <h3 className="ttl c-4">
                          MLBソフトクリーム(レモン)<br/>
                          450円
                        </h3>
                        <p>
                          3F MLB café FUKUOKA<br/>
                          MLBソフトクリームがほんのり黄色のレモン味で登場。<br/>
                          レモンの爽やかな酸味と濃厚なミルクがマッチした夏限定のソフトクリームです。
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div data-sal="slide-up"
                       data-sal-easing="ease-out-expo"
                       className="col col-4">
                    <Link to="/the-foodhall/" className="col-inner">
                      <div className="col-image">
                        <figure className="image">
                          <Image filename="summer-dessert03.jpg" width="284" height="200" />
                        </figure>
                      </div>
                      <div className="col-content bg-12">
                        <h3 className="ttl c-4">
                          サンフラワーパッションマルガリータ<br/>
                          ノンアルコール 700円<br/>
                          アルコール 850円
                        </h3>
                        <p>
                          3F MLB Café FUKUOKA<br/>
                          パッションフルーツ、ブルーキュラソー、パイナップルジュースをミックスしたフローズンカクテルに<br/>
                          ひまわりをイメージしたフレッシュパイナップルとキウイフルーツをふんだんに使った夏限定のカクテルです。
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div data-sal="slide-up"
                       data-sal-easing="ease-out-expo"
                       className="col col-4">
                    <Link to="/the-foodhall/" className="col-inner">
                      <div className="col-image">
                        <figure className="image">
                          <Image filename="summer-dessert04.jpg" width="284" height="200" />
                        </figure>
                      </div>
                      <div className="col-content bg-12">
                        <h3 className="ttl c-4">
                          トロピカルパイナップルパンチ<br/>
                          ノンアルコール 650円<br/>
                          アルコール 800円
                        </h3>
                        <p>
                          3F MLB café FUKUOKA<br/>
                          アガベシロップに漬け込んだパイナップルをチャコールでグリルしレモンジュース、パイナップルジュースと一緒にシェイクしたトロピカルカクテル。<br/>
                          別添えのココナッツシロップ入りのコーラをお好みで入れてお楽しみください。
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div data-sal="slide-up"
                       data-sal-easing="ease-out-expo"
                       className="col col-4">
                    <Link to="/the-foodhall/" className="col-inner">
                      <div className="col-image">
                        <figure className="image">
                          <Image filename="summer-dessert05.jpg" width="284" height="200" />
                        </figure>
                      </div>
                      <div className="col-content bg-12">
                        <h3 className="ttl c-4">
                          かき氷プリン味※単品不可<br/>
                          660円
                        </h3>
                        <p>
                          3F 焼肉 赤身 にくがとう<br />
                          お店自慢のふわふわかき氷に、プリン味が登場。カラメルソースをかけて、キーンと冷たいプリンの完成。
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 絶景3兄弟 SMBC日興証券 */}
        <div className="container is-fluid container-arrow bg-2" id="zekkei">
          <div className="event" id="event">
            <h2
              data-sal="slide-up"
              data-sal-easing="ease-out-expo"
              className="title is-2 c-0">
              絶景で絶叫！SNS映えも抜群<br />
              アトラクション集
            </h2>

            <div className="inner">
              <div className="spot">
                <div
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  className="spot-image">
                  <Image filename="spot3-image.jpg" width="940" height="424" />
                </div>
                <div
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  className="spot-slide">

                  <SpotImage slide={
                    [{
                      filename: 'spot3-slide01.jpg',
                    },
                      {
                        filename: 'spot3-slide02.jpg',
                      }]
                  } />
                </div>
                <div className="spot-col">
                  <div
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="spot-logo">
                    <Link to="/zekkei-brothers/"><Image filename="spot3-logo.png" width={ mq("pc") ? 600 : 300 } alt="絶景3兄弟 SMBC日興証券" /></Link>
                  </div>
                  <p data-sal="slide-up"
                     data-sal-easing="ease-out-expo"
                     className="tal text is-1 is-3-sp ls-5 lh-9">
                    眺めも気分も爽快な3種類の絶景アトラクション。全長100mのチューブ型スライダー「すべZO」、一人乗りのぶら下がり式レールコースター「つりZO」、クライミング&ボルダリング「のぼZO」!
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>

        {/* VRエリア */}
        <div className="container is-fluid container-arrow bg-17">
          <div className="event" id="event">
            <div className="inner">
              <div className="spot">
                <div
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  className="spot-image">
                  <Image filename="spot5-image.jpg" width="940" height="424" />
                </div>
                <div
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  className="spot-slide">

                  <SpotImage slide={
                    [{
                      filename: 'spot5-slide01.jpg',
                    },
                      {
                        filename: 'spot5-slide02.jpg',
                      }]
                  } />
                </div>
                <div className="spot-col">
                  <div
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="spot-logo">
                    <Link to="/v-world/"><Image filename="spot5-logo.png" width={ mq("pc") ? 600 : 300 } alt="V-World AREA クレディ・アグリコル" /></Link>
                  </div>
                  <p
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    className="tal text is-1 is-3-sp ls-5 lh-9 c-0">
                    コンテンツは18種37セット!360度の大迫力映像を体感できるドライビングシミュレーションVRや体験型音楽アトラクション、対戦型VRシューティングゲームなど複数のバーチャルコンテンツが楽しめる。
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>

        {/* 王貞治ベースボールミュージアム */}
        <div className="container is-fluid container-arrow">
          <div className="event" id="event">
            <div className="inner">
              <div className="spot">
                <div data-sal="slide-up"
                     data-sal-easing="ease-out-expo"
                     className="spot-image">
                  <Image filename="spot6-image.jpg" width="940" height="424" />
                </div>
                <div data-sal="slide-up"
                     data-sal-easing="ease-out-expo"
                     className="spot-slide">

                  <SpotImage slide={
                    [{
                      filename: 'spot6-slide01.jpg',
                    },
                      {
                        filename: 'spot6-slide02.jpg',
                      }]
                  } />
                </div>
                <div className="spot-col">
                  <div data-sal="slide-up"
                       data-sal-easing="ease-out-expo"
                       className="spot-logo">
                    <Link to="/obm/"><Image filename="spot6-logo.png" width={ mq("pc") ? 600 : 300 } alt="王貞治ベースボールミュージアム・89パーク・スタジオ" /></Link>
                  </div>
                  <p data-sal="slide-up"
                     data-sal-easing="ease-out-expo"
                     className="tal text is-1 is-3-sp ls-5 lh-9 mb10">
                    「野球人・王貞治」の歴史と魅力をたっぷり伝えるミュージアム。ホークス選手と競走できる「ホークスDASH」をはじめ、守備やバッティングなどにチャレンジできるアトラクションも充実。<br/>
                  </p>
                  <p className="underline-link">
                    <Link to="/news/obm/">元プロ野球選手がパーク内を巡回＆その他イベント</Link>
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>

        {/* グッズ情報 */}
        <div className="container is-fluid bg-21">

          <div className="goods-info">
            <h3 className="subtitle is-4 fwb tac mb-6">グッズ情報</h3>

            <div className="inner">
              <div className="sc-columns">
                <div data-sal="slide-up"
                     data-sal-easing="ease-out-expo"
                     className="col col-4">
                  <div className="col-inner">
                    <div className="col-image">
                      <figure className="image">
                        <Image filename="spot6-goods01.jpg" width="284" height="200" />
                      </figure>

                    </div>
                    <div className="col-content bg-12">
                      <h3 className="ttl c-4">
                        王貞治ベースボールミュージアム<br />
                        オリジナル王貞治グッズ発売中！
                      </h3>
                      <p>
                        4F 王貞治ベースボールミュージアム<br />
                        帽子やタオルなどから、おうちに飾りやすい扇子やサインボールも。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

        <div className="parallax-wrap2">
          <div className="description2">
            <div data-sal="slide-up"
                 data-sal-easing="ease-out-expo"
                 className="inner mb-6">
              <p>
                各アトラクションを好きな時間に楽しむなら<br />
                事前購入がおすすめ！
              </p>

              <div className="btn-wrap">
                <a
                  href="https://ticket.e-zofukuoka.com/#/"
                  className="btn-regular"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>チケット購入</span>
                  <Image src="btn-def-arrow.svg" />
                </a>
              </div>

              <p className="">
                <Link to="/ticket/how" className="underline">チケット購入方法はこちら</Link>
              </p>

            </div>

            <div data-sal="slide-up"
                 data-sal-easing="ease-out-expo"
                 className="inner">
              <div className="btn-wrap mb-6 hover">
                <Link to="/news/info/202103031986/">
                  <Image filename="bnr-parking.jpg" />
                </Link>
              </div>

              <div className="btn-wrap mb-3">
                <Link
                  to="/access/"
                  className="btn-regular"
                >
                  <span>アクセス</span>
                  <Image src="btn-def-arrow.svg" />
                </Link>
              </div>
              <div className="btn-wrap mb-6">
                <Link
                  to="/schedule/"
                  className="btn-regular"
                >
                  <span>営業時間</span>
                  <Image src="btn-def-arrow.svg" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ModalContent />
    </Layout>
  );
};

export default Template;
