/**
 * Type: コンポーネント
 * What: コンテンツリスト
 */
import { Link } from 'gatsby';
import React from 'react';
import Image from '../util/Image';

export const SummerModal1 = () => (
  <div className="e-modal-content">
    <p>発売日：7月5日（月）10:00～</p>
    <p>対象日程：７月22日(木・祝)～7月31日（土）</p>
    <p>価格（税込）：おとな（中学生以上）：1,900円 / 小学生：1,200円</p>
    <p>
      <a href="https://ticket.e-zofukuoka.com/#/" className="tx-link">
        BOSS E・ZO FUKUOKA専用チケットサイト
      </a>
      にてお買い求めいただけます。
    </p>
  </div>
);
export const SummerModal2 = () => (
  <div className="e-modal-content">
    <p>
      7月27日（火）～8月29日（日）の期間、BOSS E・ZO
      FUKUOKA、みずほPayPayドーム福岡、MARK IS
      福岡ももちの3か所を巡る宝探しイベントを開催！隠された謎を解き隠された宝物を見つけよう！見事に宝物を発見したトレジャーハンターにはクリア賞として「カクカクホークス　キラキラステッカーセット（非売品）」をプレゼントします！
    </p>
    <p>
      参加無料、リーフレット配布場所はBOSS E・ZO
      FUKUOKA、みずほPayPayドーム、MARK IS 福岡ももち
    </p>
  </div>
);
export const SummerModal3 = () => (
  <div className="e-modal-content">
    <p>
      7月17日（土）～8月29日（日）期間中、豪華景品があたる抽選会を開催します。
    </p>
    <div className="table-base">
      <table>
        <tbody>
          <tr>
            <th>抽選会開催期間</th>
            <td>7月17日（土）～8月29日（日）</td>
          </tr>
          <tr>
            <th>抽選会会場</th>
            <td>BOSS E・ZO FUKUOKA 3F MLB café FUKUOKA横特設ブース</td>
          </tr>
          <tr>
            <th>参加方法</th>
            <td>
              <p className="ttl">
                BOSS E・ZO
                FUKUOKAやみずほPayPayドーム福岡で配布するスタンプラリー用紙にスタンプを3つ集めた用紙を抽選会会場へお持ちください。
              </p>
              <p className="ttl">対象者：下記のいずれか3つを満たす方</p>
              <dl>
                <dt>①BOSS E・ZO FUKUOKAアトラクションご利用</dt>
                <dd>
                  <p className="mb0">（対象コンテンツ）</p>
                  <p className="mb0">RF 絶景3兄弟 SMBC日興証券</p>
                  <p className="mb0">
                    7F よしもと福岡 大和証券劇場
                  </p>
                  <p className="mb0">6F V-World AREA</p>
                  <p className="mb0">
                    6F CHIKYUJIN presents うんこミュージアム FUKUOKA powered by
                    HAWKS
                  </p>
                  <p className="mb0">5F チームラボフォレスト 福岡 – SBI証券</p>
                  <p className="mb0">4F 王貞治ベースボールミュージアム</p>
                  <p className="mb0">1F HKT48劇場</p>
                  <p className="list-mark mb0">
                    ※異なるアトラクション3つのご利用でも抽選会にご参加いただけます。
                  </p>
                  <p className="list-mark mb0">
                    ※1,000円（税込）以上のチケットが対象となります。
                  </p>
                </dd>
              </dl>
              <dl className="mt-5">
                <dt>②BOSS E・ZO FUKUOKA飲食店ご利用</dt>
                <dd>
                  <p className="mb0">（対象店舗）</p>
                  <p className="mb0">3F The FOODHALL各店、MLB café FUKUOKA</p>
                  <p className="list-mark mb0">
                    ※一会計1,000円（税込）以上が対象となります。
                  </p>
                </dd>
              </dl>
              <dl className="mt-5">
                <dt>③ホークスストアご利用</dt>
                <dd>
                  <p className="list-mark mb0">
                    ※一会計1,000円（税込）以上が対象となります。
                  </p>
                </dd>
              </dl>
              <dl className="mt-5">
                <dt>④みずほPayPayドーム福岡でのホークス主催試合を観戦</dt>
              </dl>
              <dl className="mt-5">
                <dt>⑤「ホークスクエスト」クリア</dt>
              </dl>
            </td>
          </tr>
          <tr>
            <th>景品一覧（一部）</th>
            <td>
              <p>
                特賞：ホークス選手直筆サイン入り使用済ユニフォーム／Nintendo
                Switch Lite／Apple AirPods　よりいずれか一つ
              </p>
              <p>
                1等：ホークス選手直筆サイン入りボール／8月15日（日）試合観戦チケットペア引換券／ヒルトン福岡シーホーク
                ペア宿泊券　よりいずれか一つ
              </p>
              <p className="tar">など</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p className="list-mark mb0">※Nintendo　Switch は任天堂の商標です。</p>
      <p className="list-mark mb0">※Apple AirPodsはAppleの商標です。</p>
    </div>
  </div>
);
export const SummerModal4 = () => (
  <div className="e-modal-content">
    <p>
      学生の皆さんがお得に楽しめる！人気のチームラボフォレスト 福岡 –
      SBI証券、V-World
      AREA、絶景３兄弟「すべZO」SMBC日興証券が通常より1,200円安い2,800円で発売します。さらに、当日チケットご購入の際に「西鉄バス
      エコルカード」をご提示いただくとセットプラン発売価格からさらに200円引きでご購入いただけます。
    </p>
    <p>
      学生に嬉しいサイズ感！MLB café FUKUOKAで学生限定価格のMLB BIG
      バーガーセットも登場します！
    </p>
    <div className="table-base">
      <h4>夏の学割セットプラン</h4>
      <table>
        <tbody>
          <tr>
            <th>発売期間</th>
            <td>7月17日（土）～8月29日（日）</td>
          </tr>
          <tr>
            <th>セット内容</th>
            <td>
              <p>①チームラボフォレスト 福岡 – SBI証券</p>
              <p>②V-World AREA＜30分遊び放題コース＞</p>
              <p>③絶景3兄弟「すべZO」SMBC日興証券</p>
            </td>
          </tr>
          <tr>
            <th>発売価格（税込）</th>
            <td>
              <p>2,800円</p>
              <p className="list-mark mb0">
                ※「西鉄バス エコルカード」のご提示でさらに200円引き
              </p>
            </td>
          </tr>
          <tr>
            <th>注意事項</th>
            <td>
              <p className="list-mark mb0">
                ※16歳以上の学生の方のみ購入いただけます。各フロアの入場口で学生証または年齢が確認できるものをご提示ください。
              </p>
              <p className="list-mark mb0">
                ※西鉄バスエコルカードは当日券売機でチケット購入前に3F・インフォメーションへご提示ください。
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <h4 className="mt-6">学生限定価格グルメ</h4>
      <div className="tac">
        <Image filename="summer-modal.jpg" />
      </div>
      <table className="mt-5">
        <tbody>
          <tr>
            <th>発売期間</th>
            <td>7月17日（土）～8月29日（日）</td>
          </tr>
          <tr>
            <th>販売店舗</th>
            <td>3F MLB café FUKUOKA</td>
          </tr>
          <tr>
            <th>販売メニュー</th>
            <td>
              <p>【学生限定】MLB BIG バーガーセット</p>
              <p>（フライドポテト・ソフトドリンク付）</p>
            </td>
          </tr>
          <tr>
            <th>価格（税込）</th>
            <td>1,000円</td>
          </tr>
          <tr>
            <th>注意事項</th>
            <td>ご注文時に学生証をご提示の方に限り対象</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);
export const SummerModal5 = () => (
  <div className="e-modal-content">
    <p>
      7月中旬より、乗車中の貴重な瞬間を撮影するサービスを開始します。撮影した写真は購入も可能で、SNS投稿に便利なデータ渡しか、データ＋手元に残る現像写真でのお渡しからお選びいただけます。つりZO体験の記念にぜひご利用ください！
    </p>
    <p>価格（税込）</p>
    <p>データ渡し：1枚500円</p>
    <p>データ＋現像渡し ：1セット1,200円</p>
    <p>購入方法</p>
    <p>
      体験後、スタッフがお渡しする購入カードをお受取りいただき、9Fロッカー横に設置の端末にて操作し購入
    </p>
  </div>
);
export const SummerModal6 = () => (
  <div className="e-modal-content">
    <p>
      BOSS E･ZO FUKUOKA 3F「MLB café
      FUKUOKA」にて、ドッグテラス・ビアテラスが同時オープンしました！
    </p>
    <p>
      テラス席のみワンちゃんと同伴OKのエリアとしてリニューアルします。また、120分飲み放題付のビアテラスもスタート！ビアテラス開催期間限定のお得なコースをご用意しております！
    </p>
    <h4>ビアテラスコース</h4>
    <div className="table-base">
      <table className="mt-5">
        <tbody>
          <tr>
            <th>発売期間</th>
            <td>7月1日（木）～9月30日（木）</td>
          </tr>
          <tr>
            <th>セット内容</th>
            <td>ビアテラスコース料理4品＋120分飲み放題</td>
          </tr>
          <tr>
            <th>価格（税込）</th>
            <td>大人　3,000円／子供（12歳まで）1,000円</td>
          </tr>
          <tr>
            <th>注意事項</th>
            <td>
              <ul>
                <li>・ご利用は2名様以上より承ります。</li>
                <li>
                  ・開催日時、メニュー内容は予告なく変更することがございます。予めご了承ください。
                </li>
                <li>
                  ・新型コロナウイルス感染予防対策の為、ご利用人数を制限させていただく場合がございます。
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);
