export const SUMMER_GOODS = [
  {
    group: 'goods',
    ttl: 'うんこミュージアムカレー(ミニサラダ付き)1,300円（税込）', // タイトル
    desc: 'うんこミュージアム公式コラボメニュー！ビーツの自然なピンクで仕上げたマイルドなヨーグルトチキンカレー。', // 説明
    img: 'spot1-goods01.jpg', // 画像名
    isModal: false,
    link: '/mlbcafe-fukuoka/', // リンク先URL 
  },
  {
    group: 'goods',
    ttl: '福岡限定ホークスコラボグッズも！', // タイトル
    img: 'spot1-goods02.jpg', // 画像名
    isModal: false,
    link: '/unkomuseum/#unkoGoods', // リンク先URL 
  },
  {
    group: 'goods',
    ttl: 'うんこミュージアム＋カブト・クワガタふれあいの森in福岡セット券', // タイトル
    img: 'spot1-goods03.jpg', // 画像名
    isModal: true,
    modalName: 'SummerModal1', // モーダル名
  },
];
export const OTHER_EVENTS = [
  {
    group: 'other',
    ttl: 'ホークスクエスト', // タイトル
    img: 'other-event1.jpg', // 画像名
    isModal: true,
    modalName: 'SummerModal2', // モーダル名

  },
  {
    group: 'other',
    ttl: 'E・ZOスタンプラリー大抽選会', // タイトル
    img: 'other-event4.jpg', // 画像名
    isModal: true,
    modalName: 'SummerModal3', // モーダル名

  },
  {
    group: 'other',
    ttl: '夏の学割セットプラン登場！', // タイトル
    img: 'other-event5.jpg', // 画像名
    isModal: true,
    modalName: 'SummerModal4', // モーダル名
  },
  {
    group: 'other',
    ttl: '景３兄弟「つりZO」SMBC日興証券フォトサービス開始！', // タイトル
    img: 'other-event2.jpg', // 画像名
    isModal: true,
    modalName: 'SummerModal5', // モーダル名
  },
  {
    group: 'other',
    ttl: 'MLB caféドッグテラス・ビアテラスオープン', // タイトル
    img: 'other-event3.jpg', // 画像名
    isModal: true,
    modalName: 'SummerModal6', // モーダル名
  },
  {
    group: 'other',
    ttl: '当日券限定！高校生2名以上でチケットがおトク！', // タイトル
    img: 'other-event6.jpg', // 画像名
    isModal: false,
    link: '/news/teamlabforest/202107163167', // リンク先URL 
  },
  {
    group: 'other',
    ttl: '1周年記念！8/15（日）たのしE・ZOデー開催！', // タイトル
    img: 'other-event7.jpg', // 画像名
    isModal: false,
    link: '/news/hkt/202107293273/', // リンク先URL 
  },
];


